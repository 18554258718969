import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import data from '../assets/data.json';
import shoppingImage from '../assets/shopping.jpg'; // Import shopping image
import movieImage from '../assets/movie.jpg'; // Import movie image
import chatImage from '../assets/chat.jpg'; // Import chat image
import quizImage from '../assets/quiz.jpg'; // Import quiz image
import financeImage from '../assets/finance.jpg'; // Import finance image

const images = {
  'Shopping List App': shoppingImage,
  'Movie App': movieImage,
  'Chat App': chatImage,
  'Quiz App': quizImage,
  'Fintech Website': financeImage
};

const Work = () => {
  return (
    <div id="work">
      <h2>WORK</h2>
      <section>
        <article>
          <Carousel
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            interval={2000}
            infiniteLoop={true}
            autoPlay={true}
          >
            {data.projects.map((project) => (
              <div key={project.title} className="workItem">
                <img src={images[project.title]} alt={project.title} />
                <aside>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <a target={'blank'} href={project.url}>
                    View Demo
                  </a>
                </aside>
              </div>
            ))}
          </Carousel>
        </article>
      </section>
    </div>
  );
};

export default Work;
