import React from 'react';
import me1 from '../assets/anant.jpg';
import me2 from '../assets/raja.jpg';
import me3 from '../assets/tanuj.jpg';

const Testimonial = () => {
  return (
    <div id="testimonial">
      <h2>Testimonial</h2>

      <section>
        <TestimonialCard
          src={me1}
          name={'Anand Chauhan'}
          position={'Batchmate - COER'}
          feedback={
            'Shubham has good interdisciplinary skills and has the ability to work in different conditions.'
          }
        />

        <TestimonialCard
          src={me2}
          name={'Vivek Agarwal'}
          position={'Sr. Superviser - Rico Auto'}
          feedback={
            'Shubham excels in handling stress and works effectively in teams with good communication.'
          }
        />

        <TestimonialCard
          src={me3}
          name={'Tanuj Kimari'}
          position={'Systems Engineer - TCS'}
          feedback={
            'Shubham knows how to work with various Front-End web technologies very well.'
          }
        />
      </section>
    </div>
  );
};

const TestimonialCard = ({ name, feedback, src, position }) => (
  <article>
    <img src={src} alt="User" />
    <h4>{name}</h4>
    <h6>{position}</h6>
    <p>{feedback}</p>
  </article>
);

export default Testimonial;
