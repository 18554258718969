// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBIhXpK1efjWjoGy77EhVfH0UOVleT1TaM',
  authDomain: 'shubham-portfolio-react.firebaseapp.com',
  projectId: 'shubham-portfolio-react',
  storageBucket: 'shubham-portfolio-react.appspot.com',
  messagingSenderId: '300903287413',
  appId: '1:300903287413:web:37ed5d6ba452911910063c',
  measurementId: 'G-0SXRHWWZ0T'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore();
// const analytics = getAnalytics(app);
