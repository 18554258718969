import React from 'react';
import {
  AiFillGithub,
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiOutlineArrowUp
} from 'react-icons/ai';

const Footer = () => {
  return (
    <footer>
      <div>
        <img
          src={'https://avatars.githubusercontent.com/u/132261743'}
          alt="Shubham"
        />

        <h2>Shubham Jaiswal</h2>
        <p>
          It's this simple, if I never try anything, I never learn anything.
        </p>
      </div>

      <aside>
        <h2>Social Media</h2>

        <article>
          <a href="https://twitter.com/Shubham78979387" target={'blank'}>
            <AiFillTwitterCircle />
          </a>
          <a
            href="https://www.linkedin.com/in/meshubhamjaiswal/"
            target={'blank'}
          >
            <AiFillLinkedin />
          </a>
          <a href="https://github.com/shubhamJaiswal23" target={'blank'}>
            <AiFillGithub />
          </a>
        </article>
      </aside>
      <a href="#home">
        <AiOutlineArrowUp />
      </a>
    </footer>
  );
};

export default Footer;
