import React, { useRef } from 'react';
import { animate, motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';
import {
  BsArrowUpRight,
  BsChevronDown,
  BsLinkedin,
  BsGithub
} from 'react-icons/bs';
import me from '../assets/logo3.png';

const Home = ({ ratio }) => {
  const clientCount = useRef(null);
  const projectCount = useRef(null);

  const animationClientsCount = () => {
    animate(0, 1000, {
      duration: 1,
      onUpdate: (v) => (clientCount.current.textContent = v.toFixed())
    });
  };
  const animationProjectsCount = () => {
    animate(0, 20, {
      duration: 1,
      onUpdate: (v) => (projectCount.current.textContent = v.toFixed())
    });
  };

  const animations = {
    h1: {
      initial: {
        x: '-100%',
        opacity: 0
      },
      whileInView: {
        x: 0,
        opacity: 1
      }
    },
    button: {
      initial: {
        y: '-100%',
        opacity: 0
      },
      whileInView: {
        y: 0,
        opacity: 1
      }
    }
  };
  return (
    <div id="home">
      <section>
        <div>
          <motion.h1 {...animations.h1}>
            Hi, I'm <br /> Shubham Jaiswal
          </motion.h1>

          <Typewriter
            options={{
              strings: ['A Self-Taught Developer', 'A Business Enthusiast'],
              autoStart: true,
              loop: true,
              cursor: '',
              wrapperClassName: 'typewriterpara'
            }}
          />

          <div>
            <a
              href="https://www.linkedin.com/in/meshubhamjaiswal/"
              target="_blank"
              rel="noreferrer"
            >
              Linked
              <BsLinkedin />
            </a>
            <a
              href="https://github.com/shubhamJaiswal23"
              target="_blank"
              rel="noreferrer"
            >
              <BsGithub /> Projects <BsArrowUpRight />
            </a>
          </div>

          <article>
            <p>
              +
              {ratio < 2 && (
                <motion.span
                  whileInView={animationClientsCount}
                  ref={clientCount}
                ></motion.span>
              )}
            </p>
            <span>hours of dedicated effort to learn web development</span>
          </article>

          <aside>
            <article>
              <p>
                +
                {ratio < 2 && (
                  <motion.span
                    ref={projectCount}
                    whileInView={animationProjectsCount}
                  ></motion.span>
                )}
              </p>
              <span>projects have been done in web development</span>
            </article>

            <article data-special>
              <p>Contact</p>
              <span>jaiswalshubham31012000@gmail.com</span>
            </article>
          </aside>
        </div>
      </section>
      <section>
        <img src={me} alt="Shubham" />
      </section>
      <BsChevronDown />
    </div>
  );
};

export default Home;
