import React from 'react';
import data from '../assets/data.json';

const Timeline = () => {
  return (
    <div id="timeline">
      <div className="timelineBox">
        {data.projects.map((item, index) => (
          <TimelineItem
            heading={item.work}
            text={item.date}
            index={index}
            key={item.work}
          />
        ))}
      </div>
    </div>
  );
};

const TimelineItem = ({ heading, text, index }) => (
  <div
    className={`timelineItem ${
      index % 2 === 0 ? 'leftTimeline' : 'rightTimeline'
    }`}
  >
    <div>
      <h3>{text}</h3>
      <p>{heading}</p>
    </div>
  </div>
);

export default Timeline;
